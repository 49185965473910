import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { ActivateComponent } from './views/activate/activate.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { LoginComponent } from './views/login/login.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { SignupComponent } from './views/signup/signup.component';
import { StartComponent } from './views/start/start.component';
import { WhoopsComponent } from './views/whoops/whoops.component';
import { AuthGuard } from './_helpers/auth-guard.service';
const routes: Routes = [
	// { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
	{
		path: '',
		component: AppLayoutComponent, canActivate:[ AuthGuard],
		children: [
			// {
			// 	path:'',loadChildren: () => import('./modules/book/book.module').then(m => m.BookModule),
			// },
			{
				path: 'book', loadChildren: () => import('./modules/book/book.module').then(m => m.BookModule),
			},
			{
				path: 'dashboard', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
			},
			{
				path: 'history', loadChildren: () => import('./modules/history/history.module').then(m => m.HistoryModule),
			},
			{
				path: 'profile', loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule),
			},
		]
	},
	{

		path: '',
		component: PublicLayoutComponent,
		children: [
			{ path: 'login', component: LoginComponent },
			{ path: 'signup', component: SignupComponent },
			{ path: 'forgot-password', component: ForgotPasswordComponent},
			{ path: 'reset-password', component: ResetPasswordComponent},
			{ path: '404', component: PageNotFoundComponent },
			{ path: 'whoops', component: WhoopsComponent },
			{ path: 'start', component: StartComponent },
			{ path: 'activate', component: ActivateComponent },
			{ path: '**', redirectTo: '/login' },
		]
	},
	

];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
