import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { Exceptionless } from '@exceptionless/browser';
import { ToastService } from '../services/toast.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private notify:ToastService, private authenticationService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
                location.reload();
            }
            
            Exceptionless.submitEvent({ source: "HttpClient", message: err });
            const ingore  = ["NotActivated","InvalidUsername","InvalidPassword"];
            if (ingore.indexOf(err.error?.message)<0){
                this.notify.error(`Something went wrong, please try again. Contact us if the problem persists ${environment.helpdeskNumber}`);
                
            }
            if (err.error?.message) {
                err = err.error.message;
            } 
            return throwError(err);
        }));
    }
}