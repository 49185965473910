import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit {
  mainForm= new FormGroup({
    email: new FormControl(localStorage.getItem("email"),[Validators.required,Validators.email]),
    code: new FormControl("", [Validators.required])
  });
  error: any;
  loading: boolean;
  invalidCode: boolean;
  constructor(private api: ApiService, private router: Router,private notify: ToastService) { }

  ngOnInit(): void {
  }
  get code() { return this.mainForm.get('code'); }

  submitForm() {
    this.mainForm.markAllAsTouched();
    this.error = null;
    if (this.mainForm.valid) {
      this.loading = true;
      this.api.postConfirmAccount(localStorage.getItem("email"), this.code.value)
        .subscribe(
          data => {
            this.invalidCode = false;
            this.notify.info('Your account has been activated.')
            this.router.navigate(['/login']);
          },
          error => {
            this.loading = false;
            if (error.status == 409) {
              this.invalidCode = true;
              return;
            }
            this.error = error;
          });
    }
  }
  resend() {
    this.loading = true;
    this.api.getConfirmationCode(localStorage.getItem("email"), localStorage.getItem("guid"))
      .subscribe(
        data => {
          this.invalidCode = false;
          this.notify.info('A new code has been sent, check your email')
          //this.router.navigate(['/login']);
        },
        error => {
          if (error.status == 409) {
            this.invalidCode = true;
            return;
          }
          this.error = error;
        },()=> this.loading=false);
  }

}
