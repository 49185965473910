<div class="cont">
	<app-loading *ngIf="loading"></app-loading>

	<h2>Login</h2>
	<form class="k-form" [formGroup]="mainForm">
		<fieldset class="k-form-fieldset">
			<legend class="k-form-legend">Enter your details below</legend>
			<kendo-formfield>
				<kendo-label [for]="email" text="Email"></kendo-label>
				<input formControlName="email" kendoTextBox #email required />
				<kendo-formerror>Please enter your email address</kendo-formerror>
			</kendo-formfield>
			<kendo-formfield>
				<kendo-label [for]="password" text="Password"></kendo-label>
				<input formControlName="password" type="password" kendoTextBox #password required />
				<kendo-formerror>Please enter your password</kendo-formerror>
			</kendo-formfield>
			<div class="k-form-buttons">
				<button kendoButton themeColor="primary" (click)="submitForm()" [disabled]="loading">Login</button>
			</div>
		</fieldset>
		<div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
		<a routerLink="/forgot-password" class="forgot-password-link">Forgotten Password?</a>
		<p class="login-wrapper-footer-text">Need an account? <a routerLink="/signup" class="text-reset">Signup here</a>
		</p>
	</form>
</div>