import { Component, OnInit } from '@angular/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnInit {
  photo: string;

  constructor(private auth: AuthService,private dialogService: DialogService) { }

  ngOnInit(): void {
    let id = localStorage.getItem("guid");
    this.photo = '/assets/images/logo.png';
    if (id) {
      this.photo = `${environment.apiUrl}/media/applogo?guid=${id}`;
    }
  }

  logOut() {
    const dialog: DialogRef = this.dialogService.open({
      
      content: "Are you sure?",
      actions: [{ text: "No" }, { text: "Yes", themeColor: "primary" }],
      width: 250,
      height: 150,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        console.log("close");
      } else {
        console.log("action", result);
        if (result.text=="Yes"){
          this.auth.logout();
        }
      }

      
      
    });
    
  }


}
