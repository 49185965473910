import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  mainForm = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email])
    
  });
  error: any;
  loading: boolean;
  constructor(private api:ApiService,private router: Router,private notify:ToastService) { }

  ngOnInit(): void {
  }


  submitForm() {
    this.mainForm.markAllAsTouched();
    this.error = null;
    if (this.mainForm.valid) {
      this.loading = true;
      let email = this.mainForm.controls.email.value;
      localStorage.setItem('email', email);
      
      this.api.sendPasswordReset(email).subscribe(
          data => {
            this.router.navigate(['/reset-password']);
            this.loading =false;
          },
          error => {
            this.loading = false;
          });
    }
  }

}
