<div *ngIf="data" class="info">
	<span>{{ data.name }}</span>
	<div *ngIf="data.contactTelephone" class="ms-2">
		<i class="bi-headset"></i>
		<!-- {{ data.contactTelephone }} -->
		<small *ngFor="let l of data.locations" class="ms-1 d-sm-block d-md-inline">
			{{l.name }} {{ l.telephone | mask: '00000 000 000'}}<br *ngIf="splitNumbers"/>
		</small>
	</div>
</div>
