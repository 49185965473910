import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { LoginComponent } from './views/login/login.component';
import { RouterModule } from '@angular/router';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { AppSharedModule } from './modules/app-shared/app-shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WhoopsComponent } from './views/whoops/whoops.component';
import { StartComponent } from './views/start/start.component';
import { ActivateComponent } from './views/activate/activate.component';
import { CompanyInfoComponent } from './components/company-info/company-info.component';
import { SignupComponent } from './views/signup/signup.component';
import { LoadingComponent } from './components/loading/loading.component';
import { PasswordStrengthMeterModule, PSM_CONFIG } from 'angular-password-strength-meter';
import zxcvbnCommonPackage from '@zxcvbn-ts/language-common'
import zxcvbnEnPackage from '@zxcvbn-ts/language-en';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component'


@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LoginComponent,
    PublicLayoutComponent,
    AppLayoutComponent,
    WhoopsComponent,
    StartComponent,
    ActivateComponent,
    CompanyInfoComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent
  ],
  imports: [
    AppSharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    PasswordStrengthMeterModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
