import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-myf-wizard-step',
  templateUrl: './myf-wizard-step.component.html',
  styleUrls: ['./myf-wizard-step.component.scss']
})
export class MyfWizardStepComponent {
  isDisabled: boolean;
  private _isActive: boolean;
	@Input() id: string;
	@Input() title: string;
	@Input() hidden: boolean;
	@Input() isValid: boolean;
	@Input() showNext: boolean;
	@Input() showPrev: boolean;
  @Input()
  set isActive(isActive: boolean) {
    this._isActive = isActive;
    this.isDisabled = false;
  }

  get isActive(): boolean {
    return this._isActive;
  }

	@Output() next: EventEmitter<any> = new EventEmitter<any>();
	@Output() previous: EventEmitter<any> = new EventEmitter<any>();
	@Output() completed: EventEmitter<any> = new EventEmitter<any>();


	constructor() {
		this.hidden = false;
		this.isValid = true;
		this.showNext = true;
		this.showPrev = true;
		this._isActive = false;
		this.isDisabled = true;
	}

}
