import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList } from '@angular/core';
import { MyfWizardStepComponent } from '../myf-wizard-step/myf-wizard-step.component';

@Component({
	selector: 'app-myf-wizard',
	templateUrl: './myf-wizard.component.html',
	styleUrls: ['./myf-wizard.component.scss']
})
export class MyfWizardComponent implements AfterContentInit {
	@ContentChildren(MyfWizardStepComponent) wizardSteps: QueryList<MyfWizardStepComponent>;

	private _steps: Array<MyfWizardStepComponent> = [];
	private _isCompleted: boolean;

	@Output() canceled: EventEmitter<any> = new EventEmitter<any>();
	@Output() stepChanged: EventEmitter<MyfWizardStepComponent> = new EventEmitter<MyfWizardStepComponent>();

	@Input() heading: string;
	@Input() completeText: string;
	@Input() contentHeight: number;

	constructor() {
		this._isCompleted = false;
	}

	ngAfterContentInit() {
		this.wizardSteps.forEach((step) => this._steps.push(step));
		this.steps[0].isActive = true;
	}

	get steps(): Array<MyfWizardStepComponent> {
		return this._steps.filter((step) => !step.hidden);
	}

	get isCompleted(): boolean {
		return this._isCompleted;
	}

	get activeStep(): MyfWizardStepComponent | undefined {
		return this.steps.find((step) => step.isActive);
	}

	set activeStep(step: MyfWizardStepComponent | undefined) {
		if (step && step !== this.activeStep && !step.isDisabled) {
			if (this.activeStep) {
				this.activeStep.isActive = false;
			}
			step.isActive = true;
			this.stepChanged.emit(step);
		}
	}

	public get activeStepIndex(): number{
		if (this.activeStep){
			return this.steps.indexOf(this.activeStep);
		}
		return -1;
	}

	get hasNextStep(): boolean {
		return this.activeStepIndex < this.steps.length - 1;
	}

	get hasPrevStep(): boolean {
		if (!this.activeStepIndex){
			return false;
		}

		return this.activeStepIndex > 0;
	}
public reset() {
	this.activeStep = this.steps[0];
}
	public goToStep(step: MyfWizardStepComponent): void {
		
		if (!this.isCompleted) {
			this.activeStep = step;
		}
	}

	public next(): void {
		if (this.hasNextStep) {
			let nextStep: MyfWizardStepComponent = this.steps[this.activeStepIndex + 1];
			this.activeStep?.next.emit();
			nextStep.isDisabled = false;
			this.activeStep = nextStep;
		}
	}

	public previous(): void {
		if (this.hasPrevStep) {
			let prevStep: MyfWizardStepComponent = this.steps[this.activeStepIndex - 1];
			this.activeStep?.previous.emit();
			prevStep.isDisabled = false;
			this.activeStep = prevStep;
		}
	}

	public complete(): void {
		if (this.activeStep){
			this.activeStep.completed.emit();
		}
		this._isCompleted = true;
	}
	public cancel(): void {
		this.canceled.emit();
	}
}
