import { Component, Injectable } from '@angular/core';
import { ActivatedRoute, Resolve, Router } from '@angular/router';
import { Exceptionless } from '@exceptionless/browser';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'salonpartner-online-booking';
  constructor(route: ActivatedRoute, auth: AuthService) {
    route.queryParams.subscribe(params => {
      let id = params['id'];
      let cid = localStorage.getItem("guid");
      if (id && id!=cid) {
        console.log("application-installing");

        localStorage.setItem("guid", id);
        auth.logout();

      }
    });

    Exceptionless.startup(c => {c.apiKey = environment.ExceptionlessApiKey;});
  }

}
