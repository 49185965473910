import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whoops',
  templateUrl: './whoops.component.html',
  styleUrls: ['./whoops.component.scss']
})
export class WhoopsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
