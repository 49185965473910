import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@progress/kendo-angular-notification';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  mainForm = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email]),
    password: new FormControl("", [Validators.required])
  });

  
  error: any;
  loading: boolean;

  constructor(public auth: AuthService, public router: Router, private route: ActivatedRoute, private notify: ToastService) {
    let id = localStorage.getItem("guid");
    if (!id) {
      // application not installed correct
      this.router.navigate(['/whoops']);
    }
  }
  get email() { return this.mainForm.get('email'); }
  get password() { return this.mainForm.get('password'); }

  ngOnInit(): void {
 
    this.email.setValue(localStorage.getItem('email'));
  
  };

  submitForm() {
    this.mainForm.markAllAsTouched();
    this.error = null;
    if (this.mainForm.valid) {
      this.loading = true;
      localStorage.setItem('email', this.email.value);
      this.auth.login(this.email.value, this.password.value, localStorage.getItem("guid")).pipe(first())
        .subscribe(
          data => {
            this.router.navigate(['/book']);
          },
          error => {
            this.loading = false;
            if (error == "NotActivated") {
              this.router.navigate(["/activate"]);
              return;
            }
            if (error == "InvalidUsername") {
              this.notify.warning(`No account found matching that username, please select signup.`);
              return;
            }
            if (error==="InvalidPassword"){
              this.notify.warning(`Incorrect password, please try again.`);
              return;
            }

            this.error = error;
          });
    }

  }
}
