<div class="cont">
	<app-loading *ngIf="loading"></app-loading>
	<h2>Activate</h2>
	<form class="k-form" [formGroup]="mainForm">
		<fieldset class="k-form-fieldset">
			<legend class="k-form-legend">We sent a code to your inbox. </legend>
			<div class="mt-5">
				<kendo-formfield>
					<kendo-label [for]="email" text="Email"></kendo-label>
					<input formControlName="email" kendoTextBox #email required />
					<kendo-formerror>Please enter your email address</kendo-formerror>
				</kendo-formfield>
				<kendo-formfield>
					<kendo-label [for]="code" text="">Activation Code</kendo-label>
					<input formControlName="code" kendoTextBox #code required />
					<kendo-formerror>Please enter your confirmation code</kendo-formerror>
				</kendo-formfield>
			</div>
				<div class="k-form-buttons">
					<button kendoButton themeColor="primary" (click)="submitForm()"
						[disabled]="loading">Confirm</button>
						<button kendoButton themeColor="secondary" (click)="resend()"
						[disabled]="loading">Resend</button>
				</div>
		</fieldset>
		<div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
		<div *ngIf="invalidCode" class="text-danger">The confirmation code does not match our records, use the re-send
			click to generate a new email.</div>
		<a routerLink="/login" >back</a>
	</form>

	<!-- GTFZ-NU3A-KK -->
</div>