import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { User } from 'src/app/models/user.model';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserTokenRequestModel } from './api/models/user.models';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject!: BehaviorSubject<User>;
  public currentUser$!: Observable<User>;
  constructor(private http: HttpClient, private router: Router) {

    this.currentUserSubject = new BehaviorSubject<User>(this.getUser());
    this.currentUser$ = this.currentUserSubject.asObservable();

  }
  getUser(): User {
    let store = localStorage.getItem('currentUser');
    if (store) {
      return JSON.parse(store);
    }

    return null;
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string, uuid: string) {
    let model = { email: username, password: password, uuid: uuid } as UserTokenRequestModel;

    return this.http.post<any>(`${environment.apiUrl}/clientaccounts/login`, model)
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }




  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(["/login"]);
  }
}
