import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { SalesChartModel } from 'src/app/models/dashboard.models';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
import { ObPossibleTimeModel, ObPossibleTimesModel, ObServiceBookingModel, ObServicesModel, ObSettingsModel } from './models/booking.models.';

import { ClientSummaryModel } from './models/client.models';
import { ClientAccountRegisterModel, DashboardTakingsModel, EmployeeListModel, LocationModel, ObAccountModel, PasswordResetModel, PeriodDateModel, SendPasswordResetModel, UserTokenRequestModel } from './models/user.models';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	public basePath = environment.apiUrl;
	constructor(private http: HttpClient, private auth: AuthService) {
		auth.currentUser$.subscribe(a => {
			console.log('auth-has-user', a?.token);
		});
	}
	getHeaders(): HttpHeaders | { [header: string]: string | string[]; } {
		let h = new HttpHeaders();
		h = h.append('Authorization', `Bearer ${this.auth.getUser}`);
		// h = h.append('Accept', 'application/json');
		return h;
	}
	getConfirmationCode(email: string, id: string): Observable<any> {
		let url = `${this.basePath}/clientaccounts/confirmation-code`;
		let params = new HttpParams();
		params = params.append('email', email);
		params = params.append('guid', id);
		return this.http.get<any>(url, { params: params });
	}
	getCompanyInfo(guid:string): Observable<any> {
		let url = `${this.basePath}/media/company-info`;
		let params = new HttpParams();
		params = params.append('guid', guid);
		return this.http.get<any>(url,{ params:params});
	}


	getOnlineBookingSettings(): Observable<ObSettingsModel> {
		let url = `${this.basePath}/onlinebooking/settings`;
		return this.http.get<ObSettingsModel>(url);
	}
	getOnlineBookingServices(date: Date, locationId: number): Observable<ObServicesModel> {
		let url = `${this.basePath}/onlinebooking/services`;
		let params = new HttpParams();
		params = params.append('id', locationId);
		params = params.append('date', date.toDateString());
		return this.http.get<ObServicesModel>(url, { params: params });
	}

	getOnlineBookingTimes(model: ObServiceBookingModel): Observable<ObPossibleTimesModel> {
		let url = `${this.basePath}/onlinebooking/times`;
		return this.http.post<ObPossibleTimesModel>(url, model);
	}

	getProfile(): Observable<ObAccountModel> {
		let url = `${this.basePath}/clientaccounts/profile`;
		return this.http.get<ObAccountModel>(url);
	}

	getClientSummary(): Observable<ClientSummaryModel> {
		let url = `${this.basePath}/clientaccounts/summary`;
		return this.http.get<ClientSummaryModel>(url);
	}
	putProfile(model: ObAccountModel): Observable<any> {
		let url = `${this.basePath}/clientaccounts/profile`;
		return this.http.put<any>(url, model);
	}

	postPreConfirm(model: ObPossibleTimeModel): Observable<ObPossibleTimeModel> {
		let url = `${this.basePath}/onlinebooking/pre-confirm`;
		return this.http.post<ObPossibleTimeModel>(url, model);
	}

	postPreConfirmCancel(model: ObPossibleTimeModel): Observable<ObPossibleTimeModel> {
		let url = `${this.basePath}/onlinebooking/pre-confirm-cancel`;
		return this.http.post<ObPossibleTimeModel>(url, model);
	}
	postBook(model: ObPossibleTimeModel): Observable<ObPossibleTimeModel> {
		let url = `${this.basePath}/onlinebooking/book`;
		return this.http.post<ObPossibleTimeModel>(url, model);
	}


	getObLogo(id: string): Observable<any> {
		let url = `${this.basePath}/media/oblogo`;
		let params = new HttpParams();
		params = params.append('guid', id);
		return this.http.get<any>(url, { params: params });
	}

	postConfirmAccount(email: string, code: string): Observable<any> {
		let url = `${this.basePath}/clientaccounts/confirm`;
		let model = {
			email: email,
			code: code
		};

		return this.http.post<any>(url, model);

	}
	sendPasswordReset(username: string) {
		let model = { email: username } as SendPasswordResetModel;
		return this.http.post<any>(`${environment.apiUrl}/clientaccounts/send-password-reset`, model);
	}
	passwordReset(username: string,password:string,code:string) {
		let model = { email: username,confirmationCode:code,password:password } as PasswordResetModel;
		return this.http.post<any>(`${environment.apiUrl}/clientaccounts/password-reset`, model);
	}


	register(model: ClientAccountRegisterModel): Observable<ClientAccountRegisterModel> {
		return this.http.post<ClientAccountRegisterModel>(`${environment.apiUrl}/clientaccounts/register`, model);
	}

}


