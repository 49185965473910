<div kendoDialogContainer></div>
<div class="sticky-top bg-white shadow-sm border-bottom  ">
    <!-- <div class="obLogo" [ngStyle]="{'background-image': 'url(' + photo + ')'}"></div> -->
    <div class="d-flex justify-content-between px-2 pt-2">
        <div>
            <a href="javascript:void(0)" class="logo">
                <img [src]="photo" class="logo">
            </a>
        </div>
        <div class="text-right">
            <app-company-info></app-company-info>
        </div>
        <!-- <div class="heading">Online Booking</div> -->
        
    </div>

    <div class="d-flex justify-content-between bg-white px-md-5  mb-3">
        <ul
            class="nav nav-pills nav-flush flex-row flex-nowrap mb-auto mx-auto text-center justify-content-between w-100 px-3 align-items-center ">
            <li class="nav-item">
                <a routerLink="/book"  [routerLinkActive]="'active'" class="nav-link py-3 px-2" tooltip="Book appointment" placement="bottom">
                    <i class="bi-book fs-1"></i>
                    <br><span>Book</span>
                </a>
            </li>
            <li>
                <a routerLink="/history" class="nav-link py-3 px-2" [routerLinkActive]="'active'" tooltip="Clients" placement="bottom">
                    <i class="bi-clock-history fs-1"></i>
                    <br><span>History</span>
                </a>
            </li>
            <li>
                <a routerLink="/profile" [routerLinkActive]="'active'" class="nav-link py-3 px-2">
                    <i class="bi-person fs-1"></i>
                    <br><span>Profile</span>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)"  (click)="logOut()" [routerLinkActive]="'active'" class="nav-link py-3 px-2">
                    <i class="bi-box-arrow-right fs-1"></i>
                    <br><span>Exit</span>
                </a>
            </li>
        </ul>
    </div>
</div>

<router-outlet></router-outlet>