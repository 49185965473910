import { Injectable, ViewChild, ViewContainerRef } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';

import { Animation as anim,Position as pos } from '@progress/kendo-angular-notification'

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private animation : anim = { type: "slide", duration: 100 };
  private position : pos = { horizontal: 'center', vertical: 'bottom' };
  constructor(private notify : NotificationService) { }

  public warning(message:string){
    this.notify.show({
      content: message,
      animation: this.animation,
      type: { style: "warning", icon: true },
      position: this.position,
    });
  }
  public info(message:string){
    this.notify.show({
      content: message,
      animation: this.animation,
      type: { style: "info", icon: true },
      position: this.position,
    });
  }
  public error(message:string){
    this.notify.show({
      content: message,
      animation: this.animation,
      type: { style: "error", icon: true },
      position: this.position,
    });
  }
}
