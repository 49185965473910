<div class="cont">
	<app-loading *ngIf="loading"></app-loading>

	<h2>Reset Password</h2>
	<form class="k-form" [formGroup]="mainForm">
		<fieldset class="k-form-fieldset">
			<legend class="k-form-legend">Enter your email address below</legend>
			<kendo-formfield>
				<kendo-label [for]="email" text="Email"></kendo-label>
				<input formControlName="email" kendoTextBox #email required />
				<kendo-formerror>Please enter your email address</kendo-formerror>
			</kendo-formfield>
			<div class="k-form-buttons">
				<button kendoButton themeColor="primary" (click)="submitForm()" [disabled]="loading">Reset Password</button>
			</div>
		</fieldset>
		<div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
		<p class="mt-3 login-wrapper-footer-text"><a routerLink="/login" class="text-reset">Back to Login</a>
		</p>
	</form>

</div>