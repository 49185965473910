import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss']
})
export class CompanyInfoComponent implements OnInit {
  data: any;
  @Input() splitNumbers:Boolean;
  constructor(private api: ApiService) { }
  ngOnInit(): void {
    this.api.getCompanyInfo(localStorage.getItem("guid")).subscribe(a=>{
      this.data = a;
    })
  }

}
