import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  mainForm = new FormGroup({
    code: new FormControl(null, [Validators.required]),
    password: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.required, Validators.email])
  });
  error: any;
  loading = false;
  passwordStrength: any;
  constructor(private api: ApiService,private router: Router,private notify: ToastService) { }

  ngOnInit(): void {

    this.mainForm.controls.email.setValue(localStorage.getItem("email"));
  }

  submitForm() {
    this.mainForm.markAllAsTouched();
    this.error = null;
    if (this.mainForm.valid) {
      this.loading = true;
      let email = this.mainForm.controls.email.value;
      localStorage.setItem('email', email);

      this.api.passwordReset(email,this.mainForm.controls.password.value,this.mainForm.controls.code.value).subscribe(
        data => {
          this.router.navigate(['/login']);
          this.notify.info('Your password was changed!');
        },
        error => {
          if (error.status===409){
            this.notify.warning("The confirmation code was not valid")
          }
          this.loading = false;
        }, () => { this.loading = false; });
    }
  }

  passwordStrengthChanged(e): void {
    console.log('got-strength', e);
    this.passwordStrength = e ?? 0;
    this.mainForm.get('password').updateValueAndValidity();
  }
}
