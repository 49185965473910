import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { TypographyModule } from "@progress/kendo-angular-typography";
import { LoadingComponent } from 'src/app/components/loading/loading.component';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ListViewModule } from "@progress/kendo-angular-listview";
import { MyfWizardComponent } from 'src/app/components/myf-wizard/myf-wizard.component';
import { MyfWizardStepComponent } from 'src/app/components/myf-wizard-step/myf-wizard-step.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DateInputsModule,
    InputsModule,
    LabelModule,
    ButtonsModule, DialogsModule, DropDownsModule,
    TypographyModule, NotificationModule,
    NgxMaskModule.forRoot(),
    ListViewModule,
  ],
  declarations: [LoadingComponent, MyfWizardComponent, MyfWizardStepComponent],
  exports: [
    FormsModule, LoadingComponent,
    ReactiveFormsModule,
    DateInputsModule,
    InputsModule,
    LabelModule,
    ButtonsModule,
    DialogsModule,
    NgxMaskModule,
    TypographyModule,
    NotificationModule,
    DropDownsModule,
    ListViewModule, MyfWizardComponent, MyfWizardStepComponent

  ]
})
export class AppSharedModule { }
