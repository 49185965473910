<!-- <div class="obLogo shadow" [ngStyle]="{'background-image': 'url(' + photo + ')'}"></div> -->
<div class="container-fluid">
	<div class="row">
		<div class="col-sm-6 col-md-7 intro-section">
			<!-- <div class="intro-content-wrapper">
					<h1 class="intro-title">SalonPartner Cloud</h1>
					<p class="intro-text">Your one stop application for salon and spa management software</p> <a
					href="https://salonpartner.co.uk" class="btn btn-read-more">Read more</a>
				</div>
				<div class="intro-section-footer">
					<nav class="footer-nav"> <a href="#!">Facebook</a> <a href="#!">Twitter</a> <a href="#!">Gmail</a>
					</nav>
				</div> -->
		</div>
		<div class="col-sm-6 col-md-5 form-section">
			<div #toast>
				<div class="brand-wrapper text-center">
					<h1>
						<a href="javascript:void(0)">
							<img [src]="photo" class="logo">
						</a>
					</h1>
				</div>
				<div class="text-center">
					<app-company-info [splitNumbers]="true"></app-company-info>
				</div>
				<router-outlet></router-outlet>
			</div>

		</div>
	</div>
</div>