import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api/api.service';
import { ClientAccountRegisterModel } from 'src/app/services/api/models/user.models';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  returnUrl: any;
  error: any;
  loading: boolean;
  public genders: Array<{ text: string; value: number }> = [
    { text: "Male", value: 1 },
    { text: "Female", value: 2 },
    { text: "Unspecified", value: 3 },
  ];

  public notifications: Array<{ text: string; value: number }> = [
    { text: "None", value: 0 },
    { text: "SMS only", value: 1 },
    { text: "Email only", value: 2 },
    { text: "SMS and Email", value: 3 },
  ];

  public min: Date = new Date(1917, 0, 1);
  public max: Date = new Date(2020, 4, 31);

  public mainForm: FormGroup 
  passwordStrength = 0;
  public get password() {
    return this.mainForm?.get("password");
  }

  constructor(public api: ApiService, public router: Router, private route: ActivatedRoute,private notify: ToastService) {
    let id = localStorage.getItem("guid");
    if (!id) {
      // application not installed correct
      this.router.navigate(['/whoops']);
    }
    this.mainForm = new FormGroup({
      firstName: new FormControl(),
      lastName: new FormControl(),
      mobile: new FormControl(),
      email: new FormControl(null, Validators.email),
      notificationType: new FormControl(null,Validators.required),
      gender: new FormControl(),
      password: new FormControl(null, [Validators.required,this.checkPasswordStrength])
    });
  }
  ngOnInit(): void {
  };
  clearForm(): void {
    this.mainForm.reset();
  }
  submitForm() {
    this.mainForm.markAllAsTouched();
    this.error = null;
    if (this.mainForm.valid) {
      this.loading = true;
      let model = {
        email: this.mainForm.get("email")?.value,
        forenames: this.mainForm.get("firstName")?.value,
        surname: this.mainForm.get("lastName")?.value,
        genderId: this.mainForm.get("gender")?.value,
        mobileTelephone: this.mainForm.get("mobile")?.value,
        password: this.mainForm.get("password")?.value,
        notificationSendingTypeId: this.mainForm.get("notificationType")?.value,
        uuid: localStorage.getItem("guid"),
      } as ClientAccountRegisterModel;
      
      this.api.register(model).pipe(first())
        .subscribe(
          data => {
            localStorage.setItem("email",model.email);
            this.router.navigate(["/activate"]);
          },
          error => {
            this.loading = false;
            if (error.status==409){
              this.notify.warning('That email address is already in use, please login instead');
              return;
            }
            this.error = error;
            
          }, () => {
            this.loading = false;
          });
    }


  }
  checkPasswordStrength: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    // let pass = group.get('password').value;
    // let confirmPass = group.get('confirmPassword').value
    // return pass === confirmPass ? null : { notSame: true }
    console.log('test-strength',this.passwordStrength);
    let res = this.password?.value?.length>0 &&  this.passwordStrength>=0 && this.passwordStrength <3 ? { weakPassword: true } : null;
    return res;
  };
  passwordStrengthChanged(e): void {
    console.log('got-strength',e);
    this.passwordStrength = e ?? 0;
    this.mainForm.get('password').updateValueAndValidity();
    

  }
}

