<!-- <div kendoTypography variant="h4">Register</div> -->
<div class="cont">
	<app-loading *ngIf="loading"></app-loading>

	<h2>Register</h2>
	<form class="k-form" [formGroup]="mainForm">
		<fieldset class="k-form-fieldset">
			<legend class="k-form-legend">Enter your details below</legend>
			<kendo-formfield>
				<kendo-label [for]="email" text="Email"></kendo-label>
				<input formControlName="email" kendoTextBox #email required />
				<kendo-formhint>Your active email, which will be used to login to your account</kendo-formhint>
				<kendo-formerror>Please enter a valid email address</kendo-formerror>
			</kendo-formfield>
			<kendo-formfield>
				<kendo-label [for]="password" text="Password"></kendo-label>
				<input formControlName="password" kendoTextBox type="password" />
				<kendo-formhint>Your password must be 8-20 characters long, contain letters and numbers, and must not
					contain spaces, special characters, or emoji.</kendo-formhint>
				<!-- <kendo-formerror>Please enter a password</kendo-formerror> -->
				<div *ngIf="password && password.dirty && password.errors?.weakPassword" class="k-form-error k-text-start">Please
					enter a stronger password</div>
				<div *ngIf="password && password.dirty && password.errors?.required" class="k-form-error k-text-start">Please enter
					a password</div>

			</kendo-formfield>
			<password-strength-meter [minPasswordLength]="8" [enableFeedback]="true"
				(strengthChange)="passwordStrengthChanged($event)" [password]="password?.value">
			</password-strength-meter>
			<kendo-formfield>
				<kendo-label [for]="mobile" text="Mobile Telephone"></kendo-label>
				<input formControlName="mobile" kendoTextBox #mobile required  mask="00000 000 000"/>
				<kendo-formhint>Your Mobile Telephone, we use it to send reminders</kendo-formhint>
				<kendo-formerror>Please enter your mobile number</kendo-formerror>
			</kendo-formfield>
			<kendo-formfield>
				<kendo-label [for]="notificationType" text="Select Notifications"></kendo-label>
				<kendo-dropdownlist
				  #notificationType
				  formControlName="notificationType"
				  [data]="notifications"
				  
				  textField="text"
				  valueField="value"
				  [valuePrimitive]="true"
				  required
				>
				</kendo-dropdownlist>
				<kendo-formerror>Please select a notification type</kendo-formerror>
			</kendo-formfield>
			<kendo-formhint>You will receive our latest updates and promotions on your email and via SMS</kendo-formhint>
			<kendo-formfield>
				<kendo-label [for]="gender" text="Select Gender"></kendo-label>
				<kendo-dropdownlist
				  #gender
				  formControlName="gender"
				  [data]="genders"
				  textField="text"
				  valueField="value"
				  [valuePrimitive]="true"
				  required
				>
				</kendo-dropdownlist>
				<kendo-formerror>Please select a gender</kendo-formerror>

			</kendo-formfield>
			<kendo-formfield>
				<kendo-label [for]="firstName" text="First Name"></kendo-label>
				<input formControlName="firstName" kendoTextBox #firstName required />
				<kendo-formhint>Your first name</kendo-formhint>
				<kendo-formerror>Please enter your first name</kendo-formerror>
			</kendo-formfield>

			<kendo-formfield>
				<kendo-label [for]="lastName" text="Last Name"></kendo-label>
				<input formControlName="lastName" kendoTextBox #lastName required />
				<kendo-formhint>Your last name</kendo-formhint>
				<kendo-formerror>Please enter your last name</kendo-formerror>
			</kendo-formfield>
			
			<a routerLink="/login" kendoButton (click)="clearForm()">Cancel</a>
			<div class="s-footer-pad"></div>

			<div class="s-footer">
				<div class="k-form-buttons">
					<button kendoButton themeColor="primary" (click)="submitForm()">Submit</button>
					<button kendoButton (click)="clearForm()">Clear</button>
				</div>
			</div>
		</fieldset>
		<div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
	</form>
	<p class="login-wrapper-footer-text">Already have an account? <a routerLink="/login" class="text-reset">Login
			here</a></p>
</div>