<div class="">
	<!-- {{ heading }} -->
	<ul class="nav nav-justified">
		<li class="nav-item" *ngFor="let step of steps" (click)="goToStep(step)"
			[ngClass]="{'active': step.isActive, 'enabled': !step.isDisabled, 'disabled': step.isDisabled, 'completed': isCompleted}">
			<span>{{step.title}}</span>
		</li>
	</ul>
</div>
<ng-content></ng-content>
<div class="s-footer-pad"></div>
<div class="s-footer">
	<div class="k-form-buttons">
		<!-- <button kendoButton (click)="clearForm()">Clear</button> -->
		<!-- <button kendoButton themeColor="primary" type="button" (click)="cancel()">Cancel</button> -->
		<button kendoButton themeColor="primary" type="button"
			[hidden]="!hasPrevStep || (activeStep && !activeStep.showPrev)" (click)="previous()">
			Previous
		</button>
		<button kendoButton themeColor="primary" type="button" (click)="next()"
			[disabled]="!activeStep || !activeStep.isValid"
			[hidden]="!hasNextStep || (activeStep && !activeStep.showNext)">
			Next
		</button>
		<button kendoButton themeColor="primary" type="button" (click)="complete()"
			[disabled]="activeStep && !activeStep.isValid" [hidden]="hasNextStep">{{ completeText }}</button>
	</div>
</div>