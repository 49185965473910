import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss']
})
export class PublicLayoutComponent implements OnInit {
  photo: string;

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    let id = localStorage.getItem("guid");
    this.photo = '/assets/images/logo.png';
    if (id) {
      this.photo = `${environment.apiUrl}/media/applogo?guid=${id}`;
    }
  }



}
